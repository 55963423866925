import React, { useRef } from 'react';
import { Link } from 'gatsby';
import AuthorWrapper from './Author.style';
import Abhay from 'common/assets/image/BlogAuthor/Abhay.png';
import Kapil from 'common/assets/image/BlogAuthor/Kapil.png';
import DivyaPrasad from 'common/assets/image/BlogAuthor/DivyaPrasadPande.png';
import Linkdlin from 'common/assets/image/agencyDigital/DataBaseImages/LinkedIn_icon.svg';

const scrollToBottom = () => {
  const start = window.pageYOffset;
  const end = document.documentElement.scrollHeight - window.innerHeight - 250; // 50 pixels before the end
  const duration = 1500; // Scroll duration in milliseconds (adjust as needed)

  const startTime =
    'now' in window.performance ? performance.now() : new Date().getTime();

  const scroll = (timestamp) => {
    const currentTime =
      'now' in window.performance ? performance.now() : new Date().getTime();
    const progress = Math.min((currentTime - startTime) / duration, 1);

    window.scrollTo(0, start + progress * (end - start));

    if (progress < 1) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};

const AuthorAComponent = ({ showDescription }) => {
  const h5Ref = useRef(null);

  return (
    <AuthorWrapper>
      <img src={Abhay} alt="Abhay Nawathey" />
      <div className="author-text">
        <div className="heading-author">
          <h5
            onClick={scrollToBottom}
            style={{ cursor: 'pointer' }}
            ref={h5Ref}
          >
            by Abhay Nawathey
          </h5>
          <Link href="https://www.linkedin.com/in/abhaynawathey/">
            <img className="icon" src={Linkdlin} alt="Linkdlin" />
          </Link>
        </div>
        {showDescription && (
          <p>
            Abhay Nawathey is Co-founder and Chief Technology Officer of
            Clodura.AI. <br /> He has more than 22 years of experience in
            technology creation and software development, having worked in
            various leadership roles for software companies.
          </p>
        )}
      </div>
    </AuthorWrapper>
  );
};

const AuthorBComponent = ({ showDescription }) => {
  const h5Ref = useRef(null);

  return (
    <AuthorWrapper>
      <img src={Kapil} alt="Kapil Khangaonkar" />
      <div className="author-text">
        <div className="heading-author">
          <h5
            onClick={scrollToBottom}
            style={{ cursor: 'pointer' }}
            ref={h5Ref}
          >
            by Kapil Khangaonkar
          </h5>
          <Link href="https://www.linkedin.com/in/kapilkhangaonkar/">
            <img className="icon" src={Linkdlin} alt="Linkdlin" />
          </Link>
        </div>
        {showDescription && (
          <p>
            Kapil Khangaonkar is Founder of Clodura.AI and Head of Sales.
            <br /> He has more than 17 years of experience in sales and
            marketing, having worked in various leadership roles for software
            companies. Kapil has developed an AI-powered sales data and
            engagement platform that does the major heavy-lifting to ensure
            sales professionals never miss any potential opportunities and
            generate more meetings. Kapil has helped countless businesses
            transform their sales strategies and achieve unprecedented success.
          </p>
        )}
      </div>
    </AuthorWrapper>
  );
};

const AuthorCComponent = ({ showDescription }) => {
  const h5Ref = useRef(null);

  return (
    <AuthorWrapper>
      <img src={DivyaPrasad} alt="Divyaprasad Pande" />
      <div className="author-text">
        <div className="heading-author">
          <h5
            onClick={scrollToBottom}
            style={{ cursor: 'pointer' }}
            ref={h5Ref}
          >
            by Divyaprasad Pande
          </h5>
          <Link href="https://www.linkedin.com/in/divyaprasadpande/">
            <img className="icon" src={Linkdlin} alt="Linkdlin" />
          </Link>
        </div>
        {showDescription && (
          <p>
            Divyaprasad Pande is Marketing Director of Clodura.AI <br /> He has
            more than 12 years of experience in marketing, having worked in
            various leadership roles for various companies. He is passionate
            about driving business growth and success through strategic
            marketing initiatives to increase brand awareness, generate leads,
            and support the sales team in achieving revenue goals. With a strong
            background in marketing and experience in the tech industry.
          </p>
        )}
      </div>
    </AuthorWrapper>
  );
};

export { AuthorAComponent, AuthorBComponent, AuthorCComponent };
