import styled from 'styled-components';

const BlogDetailWrapper = styled.section`
  max-width: 70%;
  margin: 0 auto;
  figure.image {
    text-align: center;
  }

  p {
    font-size: 16px;
    text-align: left;
  }

  /* Responsive styles for the image tag */
  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    img {
      max-width: 50%;
    }
  }

  @media (max-width: 480px) {
    img {
      max-width: 100%;
    }
  }
`;

export default BlogDetailWrapper;
