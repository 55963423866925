import styled from 'styled-components';

const AuthorWrapper = styled.section`
  /* style.css */
  max-width: 95%;
  display: flex;
  align-items: center;

  /* Style for the author's image */
  img {
    width: 70px;
    height: 70px;
    border-radius: 20%;
  }

  /* Style for the author's name */
  h5 {
    font-size: 18px;
    font-weight: bold;
  }

  /* Style for the author's description */
  p {
    font-size: 12px;
    margin-top: -30px;
    font-weight: normal;
  }

  .author-text {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .icon {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }

  .heading-author {
    display: flex;
    align-items: center;
  }

  /* Style for the LinkedIn link */
  .a {
    margin-left: 2px;
    text-decoration: none;
    color: black;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default AuthorWrapper;
