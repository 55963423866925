import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Sticky from 'react-stickynode';
import Banner from 'containers/AgencyDigital/Banner';
import Navbar from 'containers/SaasModern/Navbar';
import BlogDetailWrapper from './blogtemplate.style.js';
import Seo from 'components/seo';
import Footer from 'containers/AgencyModern/Footer';
import { saasModernTheme } from 'common/theme/saasModern';
import EmailBanner from 'containers/SaasModern/EmailBanner/';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import '../reset.css';
import Socialicon from '../../containers/Custom/Social/index.js';
import Directory from '../../containers/Directory/index.js';
import Thanksgiving from '../../containers/SaasModern/Thanksgiving/index.js';
import {
  AuthorAComponent,
  AuthorBComponent,
  AuthorCComponent,
} from '../../containers/Custom/Blogs/AuthorComponents.js';
import BookDemoButton from '../../containers/SaasModern/ScheduleAdemo/ButtonDemo.js';
import { Helmet } from 'react-helmet';

const BlogTemplate = ({ data }) => {
  const blogData = data.strapiBlog;

  const { slug, seoTitle, seoDescription, markdown, author, media } = blogData;

  const { html } = markdown.data.childMarkdownRemark;

  // Check if there is a feature image, and use it as og:image
  const ogImage =
    media && media[0] && media[0].localFile && media[0].localFile.publicURL;

  // Define author components based on the author field
  let authorComponent;

  if (author === 'Abhay') {
    authorComponent = <AuthorAComponent />;
  } else if (author === 'Kapil') {
    authorComponent = <AuthorBComponent />;
  } else if (author === 'DP') {
    authorComponent = <AuthorCComponent />;
  } else {
    authorComponent = null; // Handle cases with no author or unknown author
  }

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title={seoTitle}
          description={seoDescription}
          robots="index, follow"
          canonical={`https://www.clodura.ai/blog/${slug}/`}
        />
        <GlobalStyle />
        <Helmet>
          <meta property="og:image" content={ogImage} />
        </Helmet>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          {/* Include authorComponent within Banner */}
          <Banner
            {...blogData}
            blogDetail={true}
            authorComponent={authorComponent}
          />
          <Socialicon />
          <BlogDetailWrapper>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            {authorComponent &&
              React.cloneElement(authorComponent, { showDescription: true })}
            {/* or true, depending on your needs */}
          </BlogDetailWrapper>
          <EmailBanner />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiBlog(slug: { eq: $slug }) {
      id
      slug
      title
      seoTitle
      seoDescription
      date
      media {
        localFile {
          publicURL
        }
      }
      markdown {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      author
    }
  }
`;

export default BlogTemplate;
